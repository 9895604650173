import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PageTitle } from 'components';
import * as usersService from 'pages/users/usersService';
import * as usersActions from 'pages/users/usersRedux';
import ReactTable from 'react-table';
import { formatDate } from 'lib/util';
import { UsersPageOptions } from 'pages/users/usersPageOptions';

class Users extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.rolesToSpanish = { admin: 'Administrador', collector: 'Colector' };
  }

  async componentDidMount() {
    await this.props.dispatch(usersActions.getUsers());
    this.roles = await usersService.getRoles();
  }

  getUsersTableColumns = () => [
    ({ Header : 'Nombre', accessor : 'name', className: 'text-center' }),
    ({ Header : 'Correo', accessor : 'email', className: 'text-center' }),
    ({ id: 'userName', Header : 'Rol', accessor : user => this.rolesToSpanish[user.fk_role], className: 'text-center' }),
    ({ id: 'createdAt', Header : 'Fecha creación', accessor : (user) => formatDate(user.created_at), className: 'text-center' }),
  ]

  getUsersTableData = () => {
    const { users: { list = []}} = this.props;
    return list;
  }

  render() {
    const { users: { list: usersList = []}} = this.props;

    return (
      <div>
        <PageTitle title="Usuarios" />
        <UsersPageOptions {...this.props} rolesToSpanish={this.rolesToSpanish} />
        <ReactTable
          style={{ width: '100%' }}
          columns={this.getUsersTableColumns()}this
          data={usersList}
          minRows = {0}>
        </ReactTable>
      </div>
    );
  }
}

Users.propTypes = {
  dispatch: PropTypes.func,
};

const mapStateToProps = state => ({ users : state.users });

const connectedUsersPage = connect(mapStateToProps)(Users);
export { connectedUsersPage as Users };
import { history } from 'lib/history';
import * as loginService from 'pages/login/loginService';

//Constants section
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const LOGOUT = 'LOGOUT';

//Reducer section
let user = JSON.parse(sessionStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case LOGIN_FAILURE:
      return {};
    case LOGOUT:
      return {};
    default:
      return state;
  }
};
export default reducer;

//Actions section
const login = (username, password) => async dispatch => {
  dispatch({ type: LOGIN_REQUEST, user });
  try {
    const { user } = await loginService.login(username, password);
    dispatch({ type: LOGIN_SUCCESS, user });
    history.push('/projects');
  } catch (e) {
    dispatch({ type: LOGIN_FAILURE, e });
  }
};

const logout = () => {
  loginService.logout();
  return { type: LOGOUT };
};

export {
  login,
  logout,
};
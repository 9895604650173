import React from 'react';
import { Row, Card, CardBody, Form, FormGroup, Label, Button, Input } from 'reactstrap';
import * as longinService from 'pages/login/loginService';
import { history } from 'lib/history';


class ResetPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      newPassword: '',
      repeatPassword: '',
      successfulMessage: null,
    };
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = async e => {
    const { token } = this.props.match.params;
    const { newPassword, repeatPassword } = this.state;
    this.setState({ submitted: true });
    if (newPassword && newPassword === repeatPassword) {
      const { message } = await longinService.resetPassword(token, newPassword);
      this.setState({ successfulMessage : message });
      setTimeout(() => history.push('/login'), 5000);
    }
  }

  render() {
    const { submitted, newPassword, repeatPassword, successfulMessage } = this.state;
    return (
      <Row className="justify-content-center ffw-login-row align-items-center">
        <Card className="ffw-card-login align-self-center">
          <CardBody>
            <h2>Cambiar contraseña</h2>
            <Form>
              <FormGroup className={(submitted && !newPassword ? 'has-error' : '')}>
                <Label htmlFor="newPassword">Contraseña nueva</Label>
                <Input type="password" placeholder="Ingresar nueva contraseña" name="newPassword" value={newPassword}
                  onChange={this.handleChange} />
                {submitted && !newPassword && <Label inline className="text-danger">Debes ingresar una contraseña</Label> }
              </FormGroup>
              <FormGroup className={(submitted && !repeatPassword ? 'has-error' : '')}>
                <Label htmlFor="repeatPassword">Repetir contraseña nueva</Label>
                <Input type="password" placeholder="Repetir nueva contraseña" name="repeatPassword" value={repeatPassword}
                  onChange={this.handleChange} />
                {submitted && repeatPassword !== newPassword &&
                  <Label className="text-danger">La contraseña no coincide</Label>
                }
              </FormGroup>
              {successfulMessage &&
                <FormGroup>
                  <Label className="text-success">
                    {successfulMessage}. Te redirigiremos a la pagina de Inicio de Sesión en 5 segundos
                  </Label>
                </FormGroup>
              }
              <FormGroup>
                <Button className="btn btn-primary" onClick={this.handleSubmit}>Confirmar</Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Row>
    );
  }
}

export { ResetPassword };
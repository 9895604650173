import React from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './map.css';
import PropTypes from 'prop-types';
import { baseMapUrl } from 'config';


const colors = [ '#2EC4B6', '#FF9F1C', '#011627', '#E71D36', '#FDFFFC', '#2070a5', '#66bd63', '#A50026' ];

const createCircle = ({ latLon, color }) => L.circle(latLon, {
  color,
  fillColor: color,
  fillOpacity: 0.5,
  weight: 3,
  radius: 0,
});

class Map extends React.Component {

  constructor(props) {
    super(props);
    this.map = null;
    this.lastLayerPicker = null;
    this.objectLayers = null;
    this.lastLegend = null;
  }

  addLegend = dataLayers => {
    const legend = L.control({ position: 'bottomright' });
    legend.onAdd = map => {
      const div = L.DomUtil.create('div', 'info ffw-legend');
      div.innerHTML += `
        <strong>Leyenda</strong>
        <div>
        ${dataLayers.map((e, i) => `
            <div class="ffw-legend-element">
              <div class="ffw-legend-box" style="background-color: ${colors[i]}">
              </div>
              <div>
                <span class="ffw-legend-title">${e.name}</span>
              </div>
            </div>
          `).join('')}
        </div>`;
      return div;
    };
    legend.addTo(this.map);
    this.lastLegend = legend;
  }

  clearMap = () => {
    if (this.lastLayerPicker) {
      this.lastLayerPicker.remove();
    }
    if (this.objectLayers) {
      Object.keys(this.objectLayers).map(k => this.objectLayers[k].clearLayers());
    }
    if (this.lastLegend) {
      this.lastLegend.remove();
    }
  }

  addLayerPicker = _ => {
    const layerPicker = L.control.layers({}, this.objectLayers, { collapsed: false });
    layerPicker.addTo(this.map);
    this.lastLayerPicker = layerPicker;
  }

  buildMap = data => {
    this.clearMap();
    if (data) {
      const dataLayersNames = Object.keys(data);
      const dataLayers = dataLayersNames.map(name => ({
        name,
        layer: new L.FeatureGroup(),
        coords: data[name].map(({ latitude: lat, longitude: lng }) => ({ lat, lng })),
      }));

      this.objectLayers = {};
      dataLayers.forEach(dl => {
        this.objectLayers[dl.name] = dl.layer;
        dl.layer.addTo(this.map);
      });
      dataLayers.forEach(dl => dl.layer.addTo(this.map));

      dataLayers.map((dataLayer, i) =>
        dataLayer.coords.map(coord =>
          coord.lat && coord.lng && dataLayer.layer.addLayer(createCircle({ latLon: coord, color: colors[i] }))));
      this.map.fitBounds(dataLayers[0].layer.getBounds());

      this.addLayerPicker(dataLayers);
      this.addLegend(dataLayers);
    }
  }

  componentDidMount() {
    this.map = L.map('ffw-campaign-map', {
      center: [ 49.8419, 24.0315 ],
      zoom: 16,
      layers: [
        L.tileLayer(baseMapUrl, {}),
      ],
    });
    const { data } = this.props;
    this.buildMap(data);
  }

  componentDidUpdate = ({ data }) => {
    if (this.props.data !== data) {
      this.buildMap(this.props.data);
    }
  }

  render() {
    return <div id="ffw-campaign-map" ></div>;
  }
}

Map.propTypes = {
  data: PropTypes.object,
};


export { Map };
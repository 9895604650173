import React from 'react';
import { Card, CardHeader, CardFooter, Row, Col } from 'reactstrap';


class CardMonitoring extends React.Component {
  render() {
    return (
      <Col xs={12} sm={12} md={6} lg={4} xl={3} className="d-flex align-items-stretch" >
        <Card className="card-stats flex-fill">
          <CardHeader className="px-3">
            <Row>
              <Col>
                <h3 className="mt-0 mb-2">Monitoreos</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardFooter className="px-3 pb-1">
            <Row>
              <Col>
                <h2 className="mb-0">{this.props.dataLenght}</h2>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    );
  }
}

export { CardMonitoring };



import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import './pageTitle.css';


class PageTitle extends React.Component {
  render() {
    const { title, subtitle } = this.props;
    return (
      <Row className="ffw-page-title-row">
        <h1 className="ffw-page-title">{title}<small>{subtitle}</small></h1>
      </Row>
    );
  }
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export { PageTitle };

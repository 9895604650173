import { request, defaultHeaders, defaultJsonHeaders } from 'lib/request';
import urlJoin from 'url-join';

const getCampaign = async campaignId => {
  const response = await request(urlJoin('/web/campaign/', campaignId), {
    method: 'GET',
    ...defaultHeaders(),
  });
  return response;
};

const getCampaignData = async campaignId => {
  const response = await request(urlJoin('/web/campaign/', campaignId, 'data'), {
    method: 'GET',
    ...defaultHeaders(),
  });
  return response.data;
};

const updateCampaignData = async updatedCampaignData => {
  const response = await request('/web/campaign-data', {
    method: 'PATCH',
    ...defaultJsonHeaders(),
    body: JSON.stringify(updatedCampaignData),
  });
  return response.data;
};

const removeCampaignData = async campaignDataId => {
  const response = await request(urlJoin('/web/campaign-data/', `${campaignDataId}`), {
    method: 'DELETE',
    ...defaultHeaders(),
  });
  return response.data;
};


export { getCampaign, getCampaignData, updateCampaignData, removeCampaignData };
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';


class ProjectCreation extends React.Component {

  constructor(props) {
    super(props);
    this.formOptions = this.props.availableForms.map(({ id: value, name: label }) => ({ value, label }));
    this.state = {
      submitted: false,
      projectName : '',
      formOption: '',
    };
  }

  handleSubmit = () => {
    this.setState({ submitted: true });
    const { projectName, formOption } = this.state;
    const newProject = { name : projectName, formId: formOption.value };
    projectName && formOption && this.props.confirmCreateProject(newProject);
  }

  render() {
    const { submitted, projectName, formOption } = this.state;
    return (
      <div>
        <ModalHeader className="ffw-modal-header">Crear nuevo proyecto</ModalHeader>
        <ModalBody>
          <Form className="px-5">
            <FormGroup className="my-4">
              <Label for="projectTitle">Nombre proyecto:</Label>
              <Input type="text" placeholder="Ingresar nombre" value={projectName}
                onChange={e => this.setState({ projectName: e.target.value })} />
              {submitted && !projectName && <Label className="text-danger">Debes ingresar un nombre de proyecto</Label>}
            </FormGroup>
            <FormGroup className="my-4">
              <Label for="typeMapSelect" >Formulario:</Label>
              <Select value={formOption} onChange={formOption => this.setState({ formOption })}
                options={this.formOptions} placeholder="Seleccionar formulario" />
              {submitted && !formOption && <Label className="text-danger">Debes elegir un formulario</Label>}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSubmit}>Confirmar</Button>
        </ModalFooter>
      </div>
    );
  }
}

ProjectCreation.propTypes = {
  toggleModal: PropTypes.func,
};


export { ProjectCreation };
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { PageHeader } from 'components';
import { Container } from 'reactstrap';

const PrivateRoute = ({ component: Component, ...rest }) =>
  <Route {...rest} render={props => (
    sessionStorage.getItem('user')
      ? <div>
        <PageHeader {...rest} />
        <Container className="ffw-container">
          <Component {...props} />
        </Container>
      </div>
      : <Redirect to={{ pathname: '/login', state: { from: props.location }}} />
  )} />;

PrivateRoute.propTypes = {
  component : PropTypes.object.isRequired,
  location : PropTypes.object,
};

export {
  PrivateRoute,
};
import React from 'react';
import { Row } from 'reactstrap';
import { CardIndicator, CardChart, CardMonitoring } from 'components';
import { getNColors } from 'lib/util';

const barOptions = {
  legend: { display: false },
  scales: {
    yAxes: [ {
      ticks: {
        beginAtZero: true,
      },
    } ],
    xAxes: [ {
      barThickness : 40,
    } ],
  },
};

const doughnutOptions = {};


class Stats extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      charts: Object.keys(props.chartsData).map(k =>
        ({
          id: k,
          title: `Cantidad vs ${k}`,
          chartJsData: this.buildDoughnutOrBarData(props.chartsData[k]),
          ...this.getChartTypeAndOptions(Object.keys(props.chartsData[k]).length),
        })),
    };
  }

  getChartTypeAndOptions = lengthData => lengthData >= 3 ?
    { type : 'bar', options : barOptions } :
    { type : 'doughnut', options : doughnutOptions }

  buildDoughnutOrBarData = stats => {
    const labels = Object.keys(stats).map(k => k);
    const datasets = [ { data: labels.map(l => stats[l].length), backgroundColor :  getNColors(labels.length) } ];
    return { labels, datasets };
  }

  toggleType = (id, type) => {
    const idx = this.state.charts.map(chart => chart.id).indexOf(id);
    this.setState(prevState => {
      prevState.charts[idx].type = type === 'bar' ? 'doughnut' : 'bar';
      prevState.charts[idx].options = type === 'bar' ? doughnutOptions : barOptions;
      return {
        charts: [
          ...prevState.charts,
        ],
      };
    });
  }

  render() {
    const { indicatorsData: { dataLenght, dataByField }} = this.props;
    const { charts } = this.state;
    return (
      <div>
        <Row>
          <CardMonitoring dataLenght={dataLenght} />
          {dataByField.map(props => <CardIndicator {...props} key={props.id} />)}
        </Row>
        <Row>
          {charts.map(props => <CardChart {...props} key={props.id} toggleType={this.toggleType} />)}
        </Row>
        <Row className="invisible">
          <canvas id="hightResolutionChart"></canvas>
        </Row>
      </div>
    );
  }
}

export { Stats };

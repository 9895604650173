import * as projectsService from 'pages/projects/projectsService';
import undoable, { distinctState } from 'redux-undo';

//Constants section
const GET_PROJECTS = 'GET_PROJECTS';
const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';
const GET_PROJECT = 'GET_PROJECT';
const ADD_PROJECT = 'ADD_PROJECT';
const EDIT_PROJECT = 'EDIT_PROJECT';
const REMOVE_PROJECT = 'REMOVE_PROJECT';

//Reducer section
const initialState = { list: []};
const projectsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        list: action.list,
      };
    case GET_PROJECT:
      return {
        list: state.list,
        selected: action.selected,
      };
    case ADD_PROJECT:
      return {
        list: [ action.project, ...state.list ],
      };
    case EDIT_PROJECT: {
      const idxToUpdate = state.list.findIndex(project => project.id === action.project.id);
      return {
        list: state.list.map((project, index) => index === idxToUpdate ? action.project : project),
      };
    }
    case REMOVE_PROJECT:
      return {
        list: state.list.filter(project => project.id !== action.projectId),
      };
    case GET_PROJECTS_FAILURE:
      return {};
    default:
      return state;
  }
};

const undoableTodos = undoable(projectsReducer, { filter: distinctState() });
export default undoableTodos;

//Actions section
const getProjects = () => async dispatch => {
  try {
    const projects = await projectsService.getProjects();
    dispatch({ type: GET_PROJECTS, list : projects });
  } catch (e) {
    dispatch({ type: GET_PROJECTS_FAILURE });
  }
};

const getSelectedProject = projectId => async dispatch => {
  try {
    const project = await projectsService.getProject(projectId);
    dispatch({ type: GET_PROJECT, selected: project });
  } catch (e) {
    console.error(e);
  }
};

const createProject = newProject => async dispatch => {
  try {
    const project = await projectsService.createProject(newProject);
    dispatch({ type: ADD_PROJECT, project });
  } catch (e) {
    console.error(e);
  }
};

const updateProject = (projectId, updatedProject) => async dispatch => {
  try {
    const project = await projectsService.updateProject(projectId, updatedProject);
    dispatch({ type: EDIT_PROJECT, project });
  } catch (e) {
    console.error(e);
  }
};

const deleteProject = projectId => async dispatch => {
  try {
    dispatch({ type: REMOVE_PROJECT, projectId });
  } catch (e) {
    console.error(e);
  }
};

export {
  getProjects,
  getSelectedProject,
  createProject,
  deleteProject,
  updateProject,
};
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Card, CardBody, Form, FormGroup, Label, Col, Button, Input } from 'reactstrap';
import * as loginActions from 'pages/login/loginRedux';
import { Link } from 'react-router-dom';


class Login extends React.Component {

  constructor(props) {
    super(props);
    this.props.dispatch(loginActions.logout());
    this.state = {
      username: '',
      password: '',
      submitted: false,
    };
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;
    if (username && password) {
      dispatch(loginActions.login(username, password));
    }
  }

  render() {
    const { username, password, submitted } = this.state;
    return (
      <Row className="justify-content-center ffw-login-row align-items-center mx-0 vh-100">
        <Card className="ffw-card-login align-self-center">
          <CardBody>
            <h2>Iniciar sesión</h2>
            <Form name="form" onSubmit={this.handleSubmit}>
              <FormGroup className={(submitted && !username ? ' has-error' : '')}>
                <Label htmlFor="username">Nombre de usuario</Label>
                <Input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                {submitted && !username &&
                  <Label>El nombe de usuario es obligatorio</Label>
                }
              </FormGroup>
              <FormGroup className={(submitted && !password ? ' has-error' : '')}>
                <Label htmlFor="password">Contraseña</Label>
                <Input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                {submitted && !password &&
                  <Label>La contraseña es obligatoria</Label>
                }
              </FormGroup>
              <FormGroup row>
                <Col className="text-right">
                  <Label>
                    <Link to="/recover-password">¿Olvidaste tu contraseña?</Link>
                  </Label>
                </Col>
              </FormGroup>
              <FormGroup>
                <Button className="btn btn-primary">Iniciar Sesión</Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Row>
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func,
  loggingIn: PropTypes.bool,
};

const mapStateToProps = state => ({ loggingIn : state.login.loggingIn });

const connectedLoginPage = connect(mapStateToProps)(Login);
export { connectedLoginPage as Login };
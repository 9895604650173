import React from 'react';
import { Row, Button } from 'reactstrap';


class CampaignsPageOptions extends React.Component {

  render() {
    return (
      <Row className="justify-content-end">
        <Button className="ffw-btn-options ffw-btn-option-create" color="success" onClick={this.props.openCreation}>
          <i className="nc-icon nc-simple-add"></i>
          &nbsp;&nbsp;Crear
        </Button>
      </Row>
    );
  }
}

export { CampaignsPageOptions };

import { request, defaultHeaders, defaultJsonHeaders } from 'lib/request';
import urlJoin from 'url-join';

const getProjects = async () => {
  try {
    const response = await request('/web/project/user', {
      method: 'GET',
      ...defaultHeaders(),
    });
    return response.data;
  } catch (e) {
    //request function manage this error
  }
};

const getProject = async projectId => {
  try {
    const response = await request(urlJoin('web/project/', `${projectId}`), {
      method: 'GET',
      ...defaultHeaders(),
    });
    return response;
  } catch (e) {
    //request function manage this error
  }
};

const createProject = async project => {
  try {
    const response = await request('/web/project', {
      method: 'POST',
      ...defaultJsonHeaders(),
      body: JSON.stringify(project),
    });
    return response[0];
  } catch (e) {
    //request function manage this error
  }
};

const getFormsByUser = async () => {
  try {
    const response = await request('/web/form/user', {
      method: 'GET',
      ...defaultHeaders(),
    });
    return response.data;
  } catch (e) {
    //request function manage this error
  }
};

const deleteProject = async projectId => {
  try {
    const response = await request(urlJoin('web/project/', `${projectId}`), {
      method: 'DELETE',
      ...defaultHeaders(),
    });
    return response;
  } catch (e) {
    //request function manage this error
  }
};

const updateProject = async (projectId, updatedProject)  => {
  try {
    const response = await request(urlJoin('web/project/', `${projectId}`), {
      method: 'PATCH',
      ...defaultJsonHeaders(),
      body: JSON.stringify(updatedProject),
    });
    return response.data[0];
  } catch (e) {
    //request function manage this error
  }
};


export { getProjects, getProject, createProject, getFormsByUser, deleteProject, updateProject };
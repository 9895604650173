import { request, defaultJsonHeaders } from 'lib/request';

const login = async (username, password) => {
  try {
    const response = await request('/authenticate', {
      method: 'POST',
      ...defaultJsonHeaders(),
      body: JSON.stringify({ email: username, password }),
    });

    if (response.jwtoken) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      sessionStorage.setItem('user', JSON.stringify(response.user));
      sessionStorage.setItem('token', response.jwtoken);
    }
    return response;
  } catch (e) {
    //request function manage this error
  }
};

const logout = () => {
  // remove user from local storage to log user out
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('token');
};

const recoverPassword = async email => {
  try {
    const response = await request('/recover-password', {
      method: 'POST',
      ...defaultJsonHeaders(),
      body: JSON.stringify({ email }),
    });
    return response;
  } catch (e) {
    //request function manage this error
  }
};

const resetPassword = async (token, password) => {
  try {
    const response = await request('/reset-password', {
      method: 'PATCH',
      ...defaultJsonHeaders(),
      body: JSON.stringify({ token, password }),
    });
    return response;
  } catch (e) {
    //request manage this error
  }
};

export {
  login,
  logout,
  recoverPassword,
  resetPassword,
};
import React from 'react';
import { Card, CardBody, CardFooter, Col, CardHeader, CardText } from 'reactstrap';
import { Doughnut, Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faChartBar, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { Chart } from 'chart.js';

Chart.plugins.register({
  beforeDraw: chartInstance => {
    let ctx = chartInstance.chart.ctx;
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
  },
});

class CardChart extends React.Component {

  download = type => {
    const { options, chartJsData: data, title = 'grafico' } = this.props;
    const hrc = document.getElementById('hightResolutionChart');
    const ctx = hrc.getContext('2d');
    const animation = {
      onComplete: animation => {
        let link = document.createElement('a');
        link.href = hrc.toDataURL('image/jpeg', 1.0);
        link.download = title.toLowerCase().split(' ').join('_');
        link.click();
        ctx.clearRect(0, 0, hrc.width, hrc.height);
      },
    };
    new Chart(ctx, { type, data, options : { animation, ...options }} );
  }

  render() {
    const { title, options, chartJsData: data, toggleType, id, type } = this.props;
    return (
      <Col xs={12} sm={12} md={12} lg={6} xl={4} className="d-flex align-items-stretch" >
        <Card className="card-stats flex-fill">
          <CardHeader className="px-3">
            <h4 className="text-truncate card-title mb-3">{title}</h4>
          </CardHeader>
          <CardBody className="px-3 pt-0">
            <CardText>
              {type === 'bar' && <Bar data={data} options={options} />}
              {type === 'doughnut' && <Doughnut data={data} options={options} />}
            </CardText>
          </CardBody>
          <CardFooter className="px-3 pb-3">
            <hr />
            <Col className="text-right">
              <FontAwesomeIcon icon={type === 'bar' ? faChartPie : faChartBar}
                onClick={() => toggleType(id, type)} className="mr-2 ffw-cursor-pointer" size="lg" />
              <FontAwesomeIcon icon={faFileDownload} onClick={() => this.download(type)} className="ffw-cursor-pointer" size="lg" />
            </Col>
          </CardFooter>
        </Card>
      </Col>
    );
  }
}

export { CardChart };

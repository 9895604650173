import * as campaignService from 'pages/campaign/campaignService';


//Constants section
const GET_CAMPAIGN = 'GET_CAMPAIGN';
const GET_CAMPAIGN_FAILURE = 'GET_CAMPAIGN_FAILURE';
const UPDATE_CAMPAIGN_FAILURE = 'UPDATE_CAMPAIGN_FAILURE';
const REMOVE_CAMPAIGN_FAILURE = 'REMOVE_CAMPAIGN_FAILURE';
const RESET_CAMPAIGN_STATE = 'RESET_CAMPAIGN_STATE';

//Reducer section
const initialState = { data: [], info: {}};
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CAMPAIGN:
      return {
        data: action.campaign.data,
        info: action.campaign.info,
      };
    case GET_CAMPAIGN_FAILURE:
      return {};
    case RESET_CAMPAIGN_STATE:
      return initialState;
    default:
      return state;
  }
};
export default reducer;

//Actions section
const getCampaign = campaignId => async dispatch => {
  try {
    //TODO hay que agregar un manejo de peticiones para evitar ver los datos guardados anteriormente,
    //mostrando un "loading" en la interfaz. En lugar de limpiar el estado reseteandolo
    dispatch({ type: RESET_CAMPAIGN_STATE });
    const campaign = await campaignService.getCampaign(campaignId);
    const campaignData = await campaignService.getCampaignData(campaignId);
    dispatch({ type: GET_CAMPAIGN, campaign : { info: campaign, data: campaignData }});
  } catch (e) {
    dispatch({ type: GET_CAMPAIGN_FAILURE });
  }
};

const updateCampaignData = updatedCampaignData => async dispatch => {
  try {
    await campaignService.updateCampaignData(updatedCampaignData);
  } catch (e) {
    dispatch({ type: UPDATE_CAMPAIGN_FAILURE });
  }
};

const removeCampaignData = (campaignDataId, campaignId) => async dispatch => {
  try {
    await campaignService.removeCampaignData(campaignDataId, campaignId);
  } catch (e) {
    dispatch({ type: REMOVE_CAMPAIGN_FAILURE });
  }
};

export {
  getCampaign,
  updateCampaignData,
  removeCampaignData,
};
import React from 'react';
import PropTypes from 'prop-types';
import * as usersActions from 'pages/users/usersRedux';
import * as usersService from 'pages/users/usersService';
import { Form, Col, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';


class UserCreation extends React.Component {

  constructor(props) {
    super(props);
    this.rolesOptions = [];
    this.state = {
      userName: '',
      userEmail: '',
      userRole: '',
      userPassword: '',
    };
  }

  async componentDidMount() {
    this.rolesOptions = await usersService.getRoles();
  }

  handleSubmit = e => {
    const newUser = {
      name: this.state.userName,
      email : this.state.userEmail,
      role : this.state.userRole,
      password : this.state.userPassword,
    };
    const { dispatch } = this.props;
    if (newUser.name) {
      dispatch(usersActions.createUser(newUser));
    }
    this.props.toggleModal();
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <ModalHeader className="ffw-modal-header">Crear nuevo usuario</ModalHeader>
        <ModalBody>
          <Form>
            <br />
            <FormGroup row>
              <Col md={3} className="align-self-center">
                <Label for="userEmail">E-mail:</Label>
              </Col>
              <Col md={9}>
                <Input type="text" placeholder="Ingresar correo electronico" name="userEmail" value={this.state.userEmail}
                  onChange={this.handleChange} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={3} className="align-self-center">
                <Label for="userName">Nombre:</Label>
              </Col>
              <Col md={9}>
                <Input type="text" placeholder="Ingresar nombre de usuario" name="userName" value={this.state.userName}
                  onChange={this.handleChange} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={3} className="align-self-center">
                <Label for="userRole" >Rol:</Label>
              </Col>
              <Col md={9} >
                <Input type="select" name="userRole" onChange={this.handleChange} >
                  <option hidden>Selecciona un rol</option>
                  {this.rolesOptions.map((role, i) => <option key={i} value={role.name} >{this.props.rolesToSpanish[role.name]}</option>)}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={3} className="align-self-center">
                <Label for="userPassword">Contraseña:</Label>
              </Col>
              <Col md={9}>
                <Input type="password" placeholder="Ingresar contraseña" name="userPassword" value={this.state.userPassword}
                  onChange={this.handleChange} />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSubmit}>Confirmar</Button>
        </ModalFooter>
      </div>
    );
  }
}

UserCreation.propTypes = {
  dispatch: PropTypes.func,
  toggleModal: PropTypes.func,
};


export { UserCreation };
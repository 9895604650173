import wgs84 from 'wgs84-util';

const _prependZero = n => (n < 10) ? `0${n}` : String(n);

const formatDate = dateString => {
  const date = new Date(dateString);
  let day = _prependZero(date.getDate());
  let month = _prependZero(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const inverseFormatDate = dateString => {
  const date = new Date(dateString);
  let day = _prependZero(date.getDate());
  let month = _prependZero(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${year}/${month}/${day}`;
};

const formatDateExtension = dateString => {
  const date = new Date(dateString);
  const hour = _prependZero(date.getHours());
  const minutes = _prependZero(date.getMinutes());
  const seconds = _prependZero(date.getSeconds());
  return `${hour}:${minutes}:${seconds}`;
};

const humanReadableFormatDate = dateString => {
  const date = new Date(dateString);
  const formatedDate = formatDate(date);
  const formatedHour = formatDateExtension(date);
  return `Última modificación: ${formatedDate} a las ${formatedHour} horas`;
};

const humanReadeableCountOfStrings = arrayOfStrings => arrayOfStrings.join(', ').replace(/(.*),/, '$1 y');

const initCap = string => string ? `${string[0].toUpperCase()}${string.substring(1).toLowerCase()}` : '';

const thousandsSeparator = number => {
  const numberSplitted = parseFloat(number).toFixed(2).toString().split('.');
  const wholeNumber = numberSplitted[0];
  const decimals = numberSplitted.length === 2 ? `,${numberSplitted[1].substr(0, 2)}` : '';

  const num = wholeNumber.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
  return num.split('').reverse().join('').replace(/^[.]/, '') + decimals;
};

const dotToComma = number => number.toString().replace('.', ',');

const scrollTo = elementId => document.getElementById(elementId).scrollIntoView();

const arrayWithoutDuplicates = array => Array.from(new Set(array));

const createHtmlElement = ({ tagName, attributes }) => {
  const htmlElement = document.createElement(tagName);
  Object.keys(attributes).forEach(attrKey => htmlElement[attrKey] = attributes[attrKey]);
  return htmlElement;
};

const isObjectEmpty = obj => Object.entries(obj).length === 0 && obj.constructor === Object;

const groupByObjKey = (key, array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const jsonToGeoJson = ({ coordinates, properties }) => ({ type: 'Feature', geometry: { type: 'Point', coordinates }, properties });

const LLToUTM = ({ latitude, longitude }) => {
  const utmCoords = wgs84.LLtoUTM({ type: 'Point', coordinates: [ longitude, latitude ]});
  return {
    south: utmCoords.geometry.coordinates[1],
    west: utmCoords.geometry.coordinates[0],
    zoneNumber: utmCoords.properties.zoneNumber,
    zoneLetter: utmCoords.properties.zoneLetter,
  };
};

const UTMToLL = ({ south, west, zoneNumber, zoneLetter }) => {
  const wgs84UtilObject = { geometry : { coordinates: [ west, south ]}, properties : { zoneNumber, zoneLetter }};
  const llCoords = wgs84.UTMtoLL(wgs84UtilObject);
  return {
    latitude: llCoords.coordinates[1],
    longitude: llCoords.coordinates[0],
  };
};

const verifyValidEmail = (email) => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);


const getNColors = n => {
  const pickableColors = [ '#2EC4B6', '#FF9F1C', '#011627', '#E71D36', '#2070a5', '#66bd63', '#A50026' ];
  let iterableColors = [];
  const getRandomColor = (color = '#2EC4B6') => {
    iterableColors = iterableColors.length === 0 ? [ ...pickableColors ] : iterableColors;
    const index = Math.floor(iterableColors.length * Math.random());
    color = iterableColors[index];
    iterableColors.splice(index, 1);
    return color;
  };
  const colors =  Array(n).fill(true).map(getRandomColor);
  return colors;
};

const applyUndoToService = ({ serviceFnToApply, willBeDeleted }) => willBeDeleted && serviceFnToApply();


export {
  formatDate,
  inverseFormatDate,
  formatDateExtension,
  humanReadeableCountOfStrings,
  initCap,
  thousandsSeparator,
  dotToComma,
  scrollTo,
  arrayWithoutDuplicates,
  createHtmlElement,
  isObjectEmpty,
  groupByObjKey,
  humanReadableFormatDate,
  jsonToGeoJson,
  LLToUTM,
  UTMToLL,
  verifyValidEmail,
  getNColors,
  applyUndoToService,
};

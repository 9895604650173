import * as usersService from 'pages/users/usersService';

//Constants section
const GET_USERS = 'GET_USERS';
const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
const ADD_USER = 'ADD_USER';

//Reducer section
const initialState = { list: []};
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_USERS:
      return {
        list: action.list,
      };
    case GET_USERS_FAILURE:
      return {};
    case ADD_USER:
      return {
        list: [ action.user, ...state.list ],
      };
    default:
      return state;
  }
};
export default reducer;

//Actions section
const getUsers = () => async dispatch => {
  try {
    const users = await usersService.getUsers();
    dispatch({ type: GET_USERS, list : users });
  } catch (e) {
    dispatch({ type: GET_USERS_FAILURE });
  }
};

const createUser = newUser => async dispatch => {
  try {
    const user = await usersService.createUser(newUser);
    dispatch({ type: ADD_USER, user });
  } catch (e) {
    console.error(e);
  }
};

export {
  getUsers,
  createUser,
};
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navbar, Container, NavbarBrand, NavbarToggler, Collapse, Nav, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { history } from 'lib/history';
import { Link } from 'react-router-dom';


class PageHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
    };
  }
  toggle = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  }
  dropdownToggle = (e) => {
    this.setState(state => ({ dropdownOpen: !state.dropdownOpen }));
  }
  getBreadcumb = path => {
    switch (path) {
      case '/':
        return [ 'Projectos' ];
      case '/projects':
        return [ 'Projectos' ];
      case '/projects/:projectId/campaigns':
        return [ 'Proyectos', 'Campañas' ];
      case '/projects/:projectId/campaigns/:campaignId':
        return [ 'Proyectos', 'Campañas', this.props.campaign.name ];
      case '/users':
        return [ 'Usuarios' ];
      default:
        return [ '' ];
    }
  }

  handleClick = (n) => {
    n && history.go(-1 * n);
  }
  render() {
    const breadcumbs = this.getBreadcumb(this.props.path);
    return (
      <Navbar expand="lg" color="primary">
        <Container>
          <NavbarBrand href="/">
            Fuzzy Forms
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
          </NavbarToggler>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar>
              <Breadcrumb listClassName="ml-2 px-3 py-2 my-0 ffw-breadcrumb" tag="nav" listTag="div">
                {breadcumbs.map((breadcumbName, i) =>
                  <BreadcrumbItem key={i} tag="span" onClick={() => this.handleClick(breadcumbs.length - i - 1)}>
                    {breadcumbName}
                  </BreadcrumbItem>
                )}
              </Breadcrumb>
            </Nav>
            <Dropdown nav className="ml-auto" tag="nav" isOpen={this.state.dropdownOpen} toggle={(e) => this.dropdownToggle(e)}>
              <DropdownToggle caret nav>
                <p>{this.props.login.user.name || ''}</p>
              </DropdownToggle>
              <DropdownMenu right>
                <Link to="/users" className="ffw-link-color-alt"><DropdownItem>Administrar Usuarios</DropdownItem></Link>
                <Link to="/login" className="ffw-link-color-alt"><DropdownItem>Cerrar sesión</DropdownItem></Link>
              </DropdownMenu>
            </Dropdown>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

PageHeader.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = state => ({ campaign: state.campaign.info, login: state.login });

const connectedApp = connect(mapStateToProps)(PageHeader);
export { connectedApp as PageHeader };
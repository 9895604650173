import React from 'react';
import { Form, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';


class ProjectEdition extends React.Component {

  constructor(props) {
    super(props);
    this.formOptions = this.props.availableForms.map(({ id: value, name: label }) => ({ value, label }));
    this.state = {
      submitted: false,
      projectName: this.props.project.name,
      formOption: this.formOptions.find(opt => opt.value === parseInt(this.props.project.fk_form_id, 10)),
    };
  }

  handleSubmit = e => {
    this.setState({ submitted: true });
    const { projectName, formOption } = this.state;
    const updatedProject = { name: projectName, formId: formOption.value };
    projectName && formOption && this.props.confirmEditProject(updatedProject);
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSelectChange = formOption => this.setState({ formOption });

  render() {
    const project = this.props.project;
    const { projectName, formOption, submitted } = this.state;
    return (
      <div>
        <ModalHeader className="ffw-modal-header">Editar proyecto</ModalHeader>
        <ModalBody>
          <Form className="px-5">
            <FormGroup className="my-4">
              <Label for="projectTitle">Nombre proyecto:</Label>
              <Input type="text" placeholder="Ingresar nombre" name="projectName"
                value={projectName} onChange={this.handleChange} />
            </FormGroup>
            {submitted && !projectName && <Label className="text-danger">Debes ingresar un nombre de proyecto</Label>}
            <FormGroup className="my-4">
              <Label for="typeMapSelect" >Tipo formulario:</Label>
              <Select
                defaultValue={formOption}
                isDisabled={this.props.project.hasCampaigns}
                value={formOption}
                onChange={this.handleSelectChange}
                options={this.formOptions}
                placeholder="Seleccionar formulario"
              />
              {project.hasCampaigns &&
                <Label className="mt-1 text-danger">
                  El proyecto ya tiene campañas creadas, por lo que no es posible cambiar el formulario
                </Label>}
              {submitted && !formOption && <Label className="text-danger">Debes elegir un formulario</Label>}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSubmit}>Confirmar</Button>
        </ModalFooter>
      </div>
    );
  }
}

export { ProjectEdition };
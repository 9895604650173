import React from 'react';
import { Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';


class CampaignPageOptions extends React.Component {

  downloadCsv = () => { document.getElementById('csv-download').click(); }
  downloadKml = () => { document.getElementById('kml-download').click(); }

  render() {
    const { downloadCsvComponent, downloadKmlComponent } = this.props;
    return (
      <Row className="justify-content-end">
        <UncontrolledDropdown>
          <DropdownToggle className="ffw-btn-options ffw-btn-option-download" color="neutral" caret>
            <i className="nc-icon nc-cloud-download-93"></i>
            &nbsp;&nbsp;Descargar
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={this.downloadCsv}> CSV {downloadCsvComponent}</DropdownItem>
            <DropdownItem onClick={this.downloadKml}> KML {downloadKmlComponent}</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Row>
    );
  }
}

CampaignPageOptions.propTypes = {
  downloadKmlComponent: PropTypes.object,
  downloadCsvComponent: PropTypes.object,
};

export { CampaignPageOptions };

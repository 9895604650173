import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { humanReadableFormatDate } from 'lib/util';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';


class CampaignCard extends React.Component {
  render() {
    const { confirmDeleteCampaign, openEdition, campaign: { id, name, updated_at, created_at, fk_project_id }} = this.props;
    const latestUpdate = updated_at || created_at;
    return (
      <Row className="justify-content-center">
        <Col xs={10} sm={10} md={10}>
          <Link to={`/projects/${fk_project_id}/campaigns/${id}`} >
            <Card className="ffw-campaign-card">
              <Row>
                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                  <CardBody className="ml-1">
                    <CardTitle className="ffw-campaign-title">{name}</CardTitle>
                    <p className="card-category">
                      {humanReadableFormatDate(latestUpdate)}
                    </p>
                  </CardBody>
                </Col>
                <Col className="align-self-end text-right" xs={2} sm={2} md={2} lg={2} xl={2}>
                  <FontAwesomeIcon className="mr-2 mb-3 ffw-highlight ffw-cursor-pointer"
                    icon={faEdit} onClick={e => { e.preventDefault(); openEdition(); }} size="lg" />
                  <FontAwesomeIcon className="mr-3 mb-3 ffw-highlight-danger ffw-cursor-pointer text-danger"
                    icon={faTrashAlt} onClick={e => { e.preventDefault(); confirmDeleteCampaign(); }} size="lg" />
                </Col>
              </Row>
            </Card>
          </Link>
        </Col>
      </Row>
    );
  }
}

CampaignCard.propTypes = {
  campaign: PropTypes.object,
};

export { CampaignCard };

import React from 'react';
import { ToastrMessageWithUndo } from 'components';
import { applyUndoToService } from 'lib/util';
import { toastr } from 'react-redux-toastr';

const getToastrOptionsWithUndo = ({ serviceFnToApply, message }) => {
  const data = { toBeDeleted : true };
  const realApiCall = () => applyUndoToService({ serviceFnToApply, willBeDeleted: data.toBeDeleted });
  return ({
    timeOut: 5000,
    showCloseButton: false,
    onHideComplete: realApiCall,
    component: () => <ToastrMessageWithUndo applyInterception={() => delete data.toBeDeleted} message={message} />,
  });
};

const notifySuccessWithUndo = undoOptions => toastr.success('', getToastrOptionsWithUndo(undoOptions));


export {
  notifySuccessWithUndo,
};
import React from 'react';
import PropTypes from 'prop-types';
import { Row, FormGroup } from 'reactstrap';


class PageOptionsButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      type: '',
    };
  }

  handleAlgo = () => {
    this.toggle();
  }

  toggle = type => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      type,
    }));
  }

  render() {
    const { selectComponent } = this.props;
    return (
      <Row className="justify-content-end">
        {selectComponent &&
          <div>
            <FormGroup>
              {selectComponent}
            </FormGroup>
          </div> }
      </Row>
    );
  }
}

PageOptionsButton.propTypes = {
  selectComponent: PropTypes.object,
};

export { PageOptionsButton };

import React from 'react';
import { Row, Button, Modal } from 'reactstrap';
import { UserCreation } from 'pages/users/userCreation';


class UsersPageOptions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle = () => this.setState(prevState => ({ modal: !prevState.modal }));

  render() {
    return (
      <Row className="justify-content-end">
        <Button className="ffw-btn-options ffw-btn-option-create" color="success" onClick={() => this.toggle()}>
          <i className="nc-icon nc-simple-add"></i>
          &nbsp;&nbsp;Crear
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <UserCreation {...this.props} toggleModal={() => this.toggle()} />
        </Modal>
      </Row>
    );
  }
}

export { UsersPageOptions };

import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { history } from 'lib/history';
import { PrivateRoute } from 'components';
import {
  Login,
  ProjectsContainer,
  CampaignsContainer,
  Campaign,
  Users,
  RecoverPassword,
  ResetPassword,
} from 'pages';


class App extends React.Component {
  render() {
    return (
      <div className="ffw-app">
        <Router history={history}>
          <Switch>
            <PrivateRoute exact path="/projects" component={ProjectsContainer} />
            <PrivateRoute exact path="/" component={ProjectsContainer}  />
            <PrivateRoute exact path="/projects/:projectId/campaigns" component={CampaignsContainer} />
            <PrivateRoute exact path="/projects/:projectId/campaigns/:campaignId" component={Campaign} />
            <PrivateRoute path="/users" component={Users} />
            <Route path="/recover-password" component={RecoverPassword} />
            <Route path="/login" component={Login} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Redirect from='*' to='/' />
          </Switch>
        </Router>
      </div>
    );
  }
}

export { App };
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Col, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import Select from 'react-select';
import moment from 'moment';


class CampaignCreation extends React.Component {

  constructor(props) {
    super(props);
    this.usersOptions = this.props.availableUsers.map(({ id: value, name: label }) => ({ value, label }));
    const formSchema = this.props.selectedProject.form_schema;
    this.campaignFormOptions = Object.keys(formSchema)
      .map(k => ({ value: k, label: formSchema[k].label, isSelectableInCampaign: formSchema[k].isSelectableInCampaign }));

    this.state = {
      submitted: false,
      campaignName: '',
      campaignForm: this.campaignFormOptions.filter(cfo => cfo.isSelectableInCampaign),
      campaignUsers: [],
      campaignStartDate: null,
      campaignEndDate: null,
      isCreation: false,
      projectId: this.props.selectedProject.id,
      focusedInput: null,
    };
  }

  handleSubmit = e => {
    this.setState({ submitted: true });
    const { campaignName: name, campaignForm, campaignUsers, campaignStartDate, campaignEndDate, isCreation, projectId } = this.state;
    const newCampaign = {
      name,
      formSchema: campaignForm.map(cf => cf.value),
      usersAssignedIds: campaignUsers.map(cu => cu.value),
      startDate: campaignStartDate ? campaignStartDate.toDate() : moment().toDate(),
      plannedEndDate: campaignEndDate ? campaignEndDate.toDate() : moment().toDate(),
      kind: isCreation ? 'creation' : 'other',
      projectId,
    };

    name && campaignForm.length && campaignUsers.length && campaignStartDate && campaignEndDate
      && this.props.confirmCreateCampaign(newCampaign);
  }

  handleChangeCampaignType = () => {
    this.setState({ isCreation: !this.state.isCreation });
    if (this.state.isCreation) {
      this.setState({ campaignForm: this.state.campaignForm.filter(cf => cf.isSelectableInCampaign) });
    } else {
      this.setState({ campaignForm: this.campaignFormOptions });
    }
  }

  render() {
    const { submitted, campaignName, campaignStartDate, campaignEndDate, campaignUsers, campaignForm } = this.state;
    return (
      <div>
        <ModalHeader className="ffw-modal-header">Crear nueva campaña</ModalHeader>
        <ModalBody>
          <Form className="px-5">
            <FormGroup row>
              <Col className="my-3"  xs={12} sm={12} md={12} lg={6} xl={6} >
                <Label>Nombre:</Label>
                <Input type="text" placeholder="Ingresar nombre" value={campaignName}
                  onChange={e => this.setState({ campaignName: e.target.value })} />
                {submitted && !campaignName && <Label className="text-danger">Debes ingresar un nombre para la campaña</Label>}

              </Col>
              <Col className="my-3" xs={12} sm={12} md={12} lg={6} xl={6} >
                <Label>Fecha:</Label>
                <DateRangePicker
                  small
                  startDatePlaceholderText="Inicio"
                  startDateId="campaignStartDateId"
                  startDate={campaignStartDate}
                  endDatePlaceholderText="Termino"
                  endDate={campaignEndDate}
                  endDateId="campaignEndDateId"
                  focusedInput={this.state.focusedInput}
                  onDatesChange={({ startDate, endDate }) => this.setState({ campaignStartDate: startDate, campaignEndDate: endDate })}
                  onFocusChange={focusedInput => this.setState({ focusedInput })}
                />
                {submitted && !campaignStartDate && <Label className="text-danger">Debes ingresar una fecha de inicio</Label>}
                {submitted && !campaignEndDate && <Label className="text-danger">Debes ingresar una fecha de termino</Label>}
              </Col>
            </FormGroup>
            <FormGroup className="my-3">
              <Label>Asignar personas:</Label>
              <Select
                // value={campaignUsers}
                isMulti
                options={this.usersOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={selectedOptions => this.setState({ campaignUsers: selectedOptions ? selectedOptions : []})}
                placeholder="Seleccionar persona"
                noOptionsMessage={() => 'No existen opciones'}
              />
              {submitted && !campaignUsers.length && <Label className="text-danger">Debes asignar personas a la campaña</Label>}
            </FormGroup>
            <FormGroup className="my-4">
              <Row>
                <Col>
                  <Label>Campos formulario:</Label>
                </Col>
                <Col className="text-right">
                  <CustomInput id="campaignCreation" label="¿Es una campaña de creación?" inline checked={this.state.isCreation}
                    onChange={this.handleChangeCampaignType} type="switch" />
                </Col>
              </Row>
              <Select
                isMulti
                value={campaignForm}
                options={this.campaignFormOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={selectedOptions => this.setState({ campaignForm: selectedOptions ? selectedOptions : []})}
                placeholder="Seleccionar campo"
                noOptionsMessage={() => 'No existen opciones'}
                isOptionDisabled={option => this.state.isCreation ? false : !option.isSelectableInCampaign}
              />
              {submitted && !campaignForm.length && <Label className="text-danger">Debes seleccionar campos para el formulario</Label>}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSubmit}>Confirmar</Button>
        </ModalFooter>
      </div>
    );
  }
}

CampaignCreation.defaultProps = {
  campaign : {},
};

CampaignCreation.propTypes = {
  dispatch : PropTypes.func,
  toggleModal: PropTypes.func,
};

export { CampaignCreation };
import * as campaignsService from 'pages/campaigns/campaignsService';
import undoable, { distinctState } from 'redux-undo';

//Constants section
const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
const GET_CAMPAIGNS_FAILURE = 'GET_CAMPAIGNS_FAILURE';
const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
const EDIT_CAMPAIGN = 'EDIT_CAMPAIGN';
const REMOVE_CAMPAIGN = 'REMOVE_CAMPAIGN';
const RESET_CAMPAIGNS_STATE = 'RESET_CAMPAIGNS_STATE';

//Reducer section
const initialState = { list: []};
const campaignsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CAMPAIGNS:
      return {
        list: action.list,
      };
    case ADD_CAMPAIGN:
      return {
        list: [ action.campaign, ...state.list ],
      };
    case EDIT_CAMPAIGN: {
      const idxToUpdate = state.list.findIndex(campaign => campaign.id === action.campaign.id);
      return {
        list: state.list.map((campaign, index) => index === idxToUpdate ? action.campaign : campaign),
      };
    }
    case REMOVE_CAMPAIGN:
      return {
        list: state.list.filter(campaign => campaign.id !== action.campaignId),
      };
    case GET_CAMPAIGNS_FAILURE:
      return {};
    case RESET_CAMPAIGNS_STATE:
      return initialState;
    default:
      return state;
  }
};

const undoableTodos = undoable(campaignsReducer, { filter: distinctState() });
export default undoableTodos;

//Actions section
const getCampaigns = projectId => async dispatch => {
  try {
    dispatch({ type: RESET_CAMPAIGNS_STATE });
    const campaigns = await campaignsService.getCampaigns(projectId);
    dispatch({ type: GET_CAMPAIGNS, list: campaigns });
  } catch (e) {
    dispatch({ type: GET_CAMPAIGNS_FAILURE });
  }
};

const createCampaign = newCampaign => async dispatch => {
  try {
    const campaign = await campaignsService.createCampaign(newCampaign);
    dispatch({ type: ADD_CAMPAIGN, campaign });
  } catch (e) {
    console.error(e);
  }
};

const updateCampaign = (campaignId, updatedCampaign) => async dispatch => {
  try {
    const campaign = await campaignsService.updateCampaign(campaignId, updatedCampaign);
    dispatch({ type: EDIT_CAMPAIGN, campaign });
  } catch (e) {
    console.error(e);
  }
};

const deleteCampaign = campaignId => async dispatch => {
  try {
    dispatch({ type: REMOVE_CAMPAIGN, campaignId });
  } catch (e) {
    console.error(e);
  }
};

export {
  getCampaigns,
  createCampaign,
  updateCampaign,
  deleteCampaign,
};
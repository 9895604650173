import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PageTitle, PageOptionsButton, Map } from 'components';
import * as campaignActions from './campaignRedux';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { Nav, NavItem, NavLink, Card, CardHeader, CardBody, TabPane, TabContent, Input } from 'reactstrap';
import { CSVLink } from 'react-csv';
import { groupByObjKey, initCap, jsonToGeoJson, LLToUTM, UTMToLL } from 'lib/util';
import tokml from 'tokml';
import { CampaignPageOptions } from 'pages/campaign/campaignPageOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faBan, faEdit, faSpinner, faTrashAlt, faChartPie, faMapMarked, faTable } from '@fortawesome/free-solid-svg-icons';
import { Stats } from 'pages/campaign/stats';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const setSelectedRow = (row, actionType, context) =>
  context.setState({ selectedRowId : row.original.id, actionType, selectedRowData: row.original  });

const confirmEdit = async context => {
  const { selectedRowData, uniqueFieldKey } = context.state;
  const { id, [uniqueFieldKey] : uniqueFieldValue,
    accuracy, south, west, zoneLetter, zoneNumber, latitude, longitude,
    ...restDatum } = selectedRowData;
  const llCoords = UTMToLL({ south, west, zoneLetter, zoneNumber });
  const datum = { ...restDatum, ubicacion : { accuracy, ...llCoords }};
  context.setState({ waitingForApiResponse: true });
  await context.props.dispatch(campaignActions.updateCampaignData({ id, mainField: uniqueFieldValue, datum }));
  await context.updateTable();
  context.setState({ selectedRowId: null, selectedRowData: null, waitingForApiResponse: false });
};

const editButtons = (row, context) => {
  const { selectedRowId, waitingForApiResponse, actionType } = context.state;
  return (
    <div>
      {selectedRowId === row.original.id && actionType === 'edit' ?
        <div>
          {waitingForApiResponse ?
            <FontAwesomeIcon icon={faSpinner} spin size="lg" /> :
            <div>
              <FontAwesomeIcon className="ffw-cursor-pointer" size="lg" color="#20A966" icon={faCheckCircle}
                onClick={() => confirmEdit(context)} />
              &nbsp;
              <FontAwesomeIcon className="ffw-cursor-pointer" size="lg" color="red" icon={faBan}
                onClick={() => context.setState({ selectedRowId: null })} />
            </div>}
        </div> :
        <FontAwesomeIcon className="ffw-cursor-pointer" icon={faEdit} onClick={() => setSelectedRow(row, 'edit', context)} />
      }
    </div>);
};

const confirmRemove = async context => {
  const { selectedRowData } = context.state;
  context.setState({ waitingForApiResponse: true });
  await context.props.dispatch(campaignActions.removeCampaignData(selectedRowData.id));
  await context.updateTable();
  context.setState({ selectedRowId: null, selectedRowData: null, waitingForApiResponse: false });
};

const removeButtons = (row, context) => {
  const { selectedRowId, waitingForApiResponse, actionType } = context.state;
  return (
    <div>
      {selectedRowId === row.original.id && actionType === 'remove' ?
        <div>
          {waitingForApiResponse ?
            <FontAwesomeIcon icon={faSpinner} spin size="lg" /> :
            <div>
              <FontAwesomeIcon className="ffw-cursor-pointer" size="lg" color="#20A966" icon={faCheckCircle}
                onClick={() => confirmRemove(context)} />
              &nbsp;&nbsp;
              <FontAwesomeIcon className="ffw-cursor-pointer" size="lg" color="red" icon={faBan}
                onClick={() => context.setState({ selectedRowId: null })} />
            </div>}
        </div> :
        <FontAwesomeIcon className="ffw-cursor-pointer" icon={faTrashAlt} onClick={() => setSelectedRow(row, 'remove', context)} />
      }
    </div>);
};

const editColumn = context =>
  ({ Header : 'Editar', accessor : '', fixed: 'right', width: 50, id: 'edit-column',
    className: 'text-center', Cell: row => editButtons(row, context), sortable: false });

const removeColumn = context =>
  ({ Header : 'Borrar', accessor : '', fixed: 'right', width: 50, id: 'remove-column',
    className: 'text-center', Cell: row => removeButtons(row, context), sortable: false });

const collectorNameColumn = () =>
  ({ Header : 'Nombre Colector', accessor : 'collectorName', id: 'collectorname-column',
    className: 'text-center', sortable: false });

const ubicationColumns = renderEditable => [
  { Header: 'Precisión', accessor: 'accuracy', width: 80, className: 'text-center', Cell: renderEditable },
  { Header: 'Oeste', accessor: 'west', className: 'text-center', Cell: renderEditable },
  { Header: 'Sur', accessor: 'south', className: 'text-center', Cell: renderEditable },
  { Header: 'Huso horario', accessor: 'zoneNumber', className: 'text-center', Cell: renderEditable },
];

const calcIndicatorStats = (field, dataset) => {
  const dataByField = dataset.map(data => data[field]);
  const average = dataByField.reduce((a, b) => a + Number(b), 0) / dataset.length;
  const max = Math.max(...dataByField);
  const min = Math.min(...dataByField);
  return { average, max, min };
};

class Campaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'dataTab',
      mapData: null,
      fieldSelected: '',
      uniqueFieldKey: null,
      selectedRowId: null,
      selectedRowData: null,
      actionType: null,
      waitingForApiResponse: false,
    };
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  updateTable = async () => {
    const { campaignId } = this.props.match.params;
    await this.props.dispatch(campaignActions.getCampaign(campaignId));
    const formSchema = this.props.campaign.info.form_schema;
    const uniqueFieldKey = Object.keys(formSchema).find(k => formSchema[k].unique);
    this.setState({ uniqueFieldKey });
  }

  async componentDidMount() {
    this.updateTable();
  }

  handleChange = (value, mapDataByField) => {
    this.setState({
      fieldSelected: value,
      mapData: mapDataByField[value],
    });
  }

  buildKmlFile = campaignDataTableData => {
    const geojson = {
      type: 'FeatureCollection',
      features: campaignDataTableData.map(({ longitude, latitude, ...data }) => jsonToGeoJson({
        coordinates : [ longitude, latitude ],
        properties : { ...data },
      })),
    };
    const kmlFileString = tokml(geojson);
    let kmlFile = new Blob([ kmlFileString ], { type: 'application/vnd.google-earth.kml+xml' });
    return URL.createObjectURL(kmlFile);
  }

  renderEditable = cellInfo =>
    cellInfo.original.id === this.state.selectedRowId && this.state.actionType === 'edit' ?
      <div
        className="form-control ffw-form-control"
        style={{ backgroundColor: '#fafafa' }}
        contentEditable
        suppressContentEditableWarning
        onKeyUp={e => {
          this.setState({ selectedRowData: { ...this.state.selectedRowData, [cellInfo.column.id]: e.target.innerHTML }});
        }}
        dangerouslySetInnerHTML={{ __html: this.data[cellInfo.index][cellInfo.column.id] }} />
      : <div>{cellInfo.value}</div>;

  render() {
    const { form_schema = {}, name } = this.props.campaign.info;
    const { data } = this.props.campaign;

    //TODO hacer toda la "acomodación" de datos en el componentDidMount, para que no se ejecute cada vez que se cambie de tab
    const campaignDataTableData = data
      .map(({ datum: { ubicacion = {}, ...restDatum }, main_field, id, collector_name, created_at }) => {
        const { latitude, longitude } = ubicacion;
        const ubicationUTM = LLToUTM({ latitude, longitude });
        const dataDate = new Date(created_at);
        return ({
          ...ubicacion,
          ...restDatum,
          [this.state.uniqueFieldKey]: main_field, id,
          ...ubicationUTM,
          collectorName : collector_name,
          createdAt: dataDate.toLocaleString(),
        });
      });

    this.data = campaignDataTableData;

    const datumCampaignDataTableColumns = Object.keys(form_schema)
      .filter(key => form_schema[key].type !== 'photo' && form_schema[key].type !== 'location')
      .sort((a, b) => form_schema[a].uiPosition - form_schema[b].uiPosition)
      .map(key => ({ Header: form_schema[key].label, accessor : key, className: 'text-center', Cell: this.renderEditable }));
    const campaignDataTableColumns = [
      editColumn(this),
      removeColumn(this),
      ...ubicationColumns(this.renderEditable),
      collectorNameColumn(this.renderEditable),
      ...datumCampaignDataTableColumns,
      { Header: 'Fecha de creación', accessor : 'createdAt', className: 'text-center', width: 150 },
    ];

    const campaignDataTableCSVHeaders = campaignDataTableColumns
      .filter(({ id }) => id !== 'edit-column' && id !== 'remove-column')
      .map(({ Header: label, accessor: key }) => ({ label, key }));

    const fieldsToMap = Object.keys(form_schema)
      .filter(key => form_schema[key].type === 'select');
    //TODO dejar data lista para consumir por el componente mapa
    const mapDataByField = {};
    fieldsToMap.forEach(field => mapDataByField[field] = groupByObjKey(field, campaignDataTableData));

    const fieldsToIndicators = Object.keys(form_schema)
      .filter(key => form_schema[key].type === 'number' && form_schema[key].valueType === 'float');

    const indicatorsData = {
      dataLenght : campaignDataTableData.length,
      dataByField : fieldsToIndicators.map(field =>({ id: field, title: field, ...calcIndicatorStats(field, campaignDataTableData) })),
    };

    return (
      <div>
        <PageTitle title={'Campaña'} subtitle={initCap(name)}/>
        <Card className="card-plain">
          <CardHeader>
            <Nav pills className="nav-pills-primary">
              <NavItem>
                <NavLink className={this.state.activeTab === 'dataTab' ? 'active' : 'inactive'}
                  onClick={() => { this.toggleTab('dataTab'); }}>
                  <FontAwesomeIcon className="mr-2" icon={faTable} />
                  Datos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={this.state.activeTab === 'mapTab' ? 'active' : 'inactive'}
                  onClick={() => { this.handleChange(fieldsToMap[0], mapDataByField); this.toggleTab('mapTab'); }}>
                  <FontAwesomeIcon className="mr-2" icon={faMapMarked} />
                  Mapas
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={this.state.activeTab === 'statsTab' ? 'active' : 'inactive'}
                  onClick={() => { this.toggleTab('statsTab'); }}>
                  <FontAwesomeIcon className="mr-2" icon={faChartPie} />
                  Estadisticas
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="dataTab">
                {campaignDataTableData.length !== 0 && this.state.activeTab === 'dataTab' ?
                  <div>
                    <CampaignPageOptions
                      downloadCsvComponent={ <CSVLink
                        id="csv-download" data={campaignDataTableData} headers={campaignDataTableCSVHeaders}
                        filename="datos.csv" target="_blank" /> }
                      downloadKmlComponent={
                        <a id="kml-download" download="datos.kml" href={this.buildKmlFile(campaignDataTableData)}
                          style={{ display: 'none' }} target="_blank" rel="noopener noreferrer" >&nbsp;</a>
                      } />
                    <ReactTableFixedColumns columns={campaignDataTableColumns} data={campaignDataTableData}
                      defaultSorted={[ { id: 'id' } ]} />
                  </div> : <div><br/>No se encontraron datos para la campaña</div> }
              </TabPane>
              <TabPane tabId="mapTab">
                {this.state.activeTab === 'mapTab' && campaignDataTableData.length !== 0 ?
                  <div>
                    <h3 className="float-left">{initCap(this.state.fieldSelected)}</h3>
                    <PageOptionsButton selectComponent={
                      <Input className="ffw-btn-options ffw-btn-options-type" type="select" name="select"
                        id="typeMapSelect" onChange={(e) => this.handleChange(e.currentTarget.value, mapDataByField)}>
                        <option hidden>Tipo de mapa</option>
                        {fieldsToMap.map((option, k) => <option key={k} value={option}>{option}</option>)}
                      </Input>}
                    />
                    <Map data={this.state.mapData} />
                  </div> : <div><br/>No se encontraron datos para la campaña</div> }
              </TabPane>
              <TabPane tabId="statsTab">
                {this.state.activeTab === 'statsTab' && campaignDataTableData.length !== 0 ?
                  <Stats {...this.props} chartsData={mapDataByField} indicatorsData={indicatorsData} />
                  : <div><br/>No se encontraron datos para la campaña</div>
                }
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
    );
  }
}

Campaign.propTypes = {
  dispatch : PropTypes.func.isRequired,
  campaign : PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = state => ({ campaign: state.campaign });

const connectedCampaignPage = connect(mapStateToProps)(Campaign);
export { connectedCampaignPage as Campaign };

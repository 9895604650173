import { KnownError } from 'lib/knowError';
import urlJoin from 'url-join';
import { ffServerUrl } from 'config';

const getToken = () => sessionStorage.getItem('token');

const getAuthorizationHeader = () => getToken() ? ({ 'Authorization': `Bearer ${getToken()}` }) : {};

const jsonContentType = { 'Content-Type': 'application/json' };
const defaultHeaders = () => ({ headers: getAuthorizationHeader() });
const defaultJsonHeaders = () => ({ headers: Object.assign({}, getAuthorizationHeader(), jsonContentType) });

const request = async (url, options) => {
  try {
    const response = await fetch(urlJoin(ffServerUrl, url), options);
    if (response.ok) {
      return response.headers.get('Content-Type').indexOf('application/json') >= 0 ? response.json() : response;
    } else {
      //TODO agregar notificaciones, para que los errores de la capa de servicio se muestren
      const serverError = await response.json();
      throw new KnownError(serverError.message);
    }
  } catch (e) {
    const err = e instanceof KnownError ? e : new Error('Ha ocurrido un error en la conexión');
    console.error(err);
    throw err;
  }
};

export {
  request,
  defaultHeaders,
  defaultJsonHeaders,
};


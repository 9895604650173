import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Modal } from 'reactstrap';
import { PageTitle } from 'components';
import * as campaignsActions from 'pages/campaigns/campaignsRedux';
import * as projectsActions from 'pages/projects/projectsRedux';
import * as campaignsService from 'pages/campaigns/campaignsService';
import * as usersService from 'pages/users/usersService';
import { notifySuccessWithUndo } from 'lib/toastrUtil';
import { CampaignsPageOptions, CampaignCard, CampaignCreation, CampaignEdition } from 'pages/campaigns/components';


class CampaignsContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      modalType: 'create',
      availableUsers : [],
      selectedCampaign : null,
      assignedUsers: [],
    };
  }

  toggle = () => this.setState(prevState => ({ modalShow: !prevState.modalShow }));

  async componentDidMount() {
    const { projectId } = this.props.match.params;
    this.props.getCampaigns(projectId);
    this.props.getSelectedProject(projectId);
    this.setState({ availableUsers : await usersService.getUsers() });
  }

  handleRemove = async campaignId => {
    this.props.deleteCampaign(campaignId);
    notifySuccessWithUndo({
      serviceFnToApply: () => campaignsService.deleteCampaign(campaignId),
      message: 'La campaña se elimino exitosamente',
    });
  }

  openCreateModal = () => {
    this.setState(prevState => ({
      modalShow: !prevState.modalShow,
      modalType: 'create',
    }));
  }

  openEditModal = async selectedCampaign => {
    const assignedUsers = await campaignsService.getAssignedUsers(selectedCampaign.id);
    this.setState(prevState => ({
      modalShow: !prevState.modalShow,
      modalType: 'edit',
      assignedUsers,
      selectedCampaign,
    }));
  };

  openCreateModal = () => {
    this.setState(prevState => ({
      modalShow: !prevState.modalShow,
      modalType: 'create',
    }));
  };

  render() {
    const campaignsList = this.props.campaigns.list;
    const { modalShow, modalType, availableUsers, selectedCampaign, assignedUsers } = this.state;
    return (
      <div>
        <PageTitle title="Campañas" />
        <CampaignsPageOptions openCreation={() => this.openCreateModal('create')}  />
        {campaignsList.length ?
          campaignsList.map(campaign => <CampaignCard key={campaign.id} campaign={campaign}
            confirmDeleteCampaign={() => this.handleRemove(campaign.id)} openEdition={() => this.openEditModal(campaign)} />) :
          <Row className="text-center">
            <Col>
              <p>No hay campañas para mostrar, puedes crear tu primer campaña presionando en 'Crear'.</p>
            </Col>
          </Row>
        }
        <Modal isOpen={modalShow} size="lg" toggle={this.toggle}>
          {modalType === 'create' &&
            <CampaignCreation selectedProject={this.props.selectedProject} availableUsers={availableUsers}
              confirmCreateCampaign={newCampaign => { this.props.confirmCreate(newCampaign); this.toggle(); }} />}
          {modalType === 'edit' &&
            <CampaignEdition selectedProject={this.props.selectedProject} campaign={selectedCampaign}
              availableUsers={availableUsers} assignedUsers={assignedUsers}
              confirmEditCampaign={updatedCampaign => { this.props.updateCampaign(selectedCampaign.id, updatedCampaign); this.toggle();}}/>}
        </Modal>
      </div>
    );
  }
}

CampaignsContainer.propTypes = {
  dispatch : PropTypes.func,
  campaigns : PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = state => ({
  campaigns: state.campaigns.present,
  projects: state.projects,
  selectedProject: state.projects.present.selected,
});

const mapDispatchToProps = dispatch => ({
  getCampaigns: projectId => dispatch(campaignsActions.getCampaigns(projectId)),
  confirmCreate: newCampaign => dispatch(campaignsActions.createCampaign(newCampaign)),
  deleteCampaign: campaignId => dispatch(campaignsActions.deleteCampaign(campaignId)),
  updateCampaign: (campaignId, updatedCampaign) => dispatch(campaignsActions.updateCampaign(campaignId, updatedCampaign)),
  getSelectedProject: projectId => dispatch(projectsActions.getSelectedProject(projectId)),
});

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(CampaignsContainer);
export { connectedComponent as CampaignsContainer };
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Modal } from 'reactstrap';
import { PageTitle } from 'components';
import * as projectsActions from 'pages/projects/projectsRedux';
import * as projectsService from 'pages/projects/projectsService';
import * as campaignsService from 'pages/campaigns/campaignsService';
import { notifySuccessWithUndo } from 'lib/toastrUtil';
import { ProjectCard, ProjectsPageOptions, ProjectCreation, ProjectEdition } from 'pages/projects/components';


class ProjectsContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      modalType: 'create',
      selectedProject : null,
      availableForms : [],
    };
  }

  toggle = () => this.setState(prevState => ({ modalShow: !prevState.modalShow }));

  async componentDidMount() {
    this.props.getProjects();
    this.setState({ availableForms : await projectsService.getFormsByUser() });
  }

  handleRemove = async projectId => {
    this.props.deleteProject(projectId);
    notifySuccessWithUndo({
      serviceFnToApply: () => projectsService.deleteProject(projectId),
      message: 'El proyecto se elimino exitosamente',
    });
  }

  openEditModal = async project => {
    const campaigns = await campaignsService.getCampaigns(project.id);
    this.setState(prevState => ({
      modalShow: !prevState.modalShow,
      modalType: 'edit',
      selectedProject: { ...project, hasCampaigns: campaigns.length > 0 },
    }));
  };

  openCreateModal = () => {
    this.setState(prevState => ({
      modalShow: !prevState.modalShow,
      modalType: 'create',
    }));
  }

  render() {
    const projects = this.props.projects.list;
    const { modalShow, modalType, selectedProject, availableForms } = this.state;
    return (
      <div>
        <PageTitle title="Proyectos" />
        <ProjectsPageOptions openCreation={this.openCreateModal} />
        <br />
        {projects.length ?
          projects.map(project =>
            <ProjectCard key={project.id} project={project}
              confirmDeleteProject={() => this.handleRemove(project.id)} openEdition={() => this.openEditModal(project)} />) :
          <Row className="text-center">
            <Col>
              <p>No hay proyectos para mostrar, puedes crear tu primer proyecto presionando en 'Crear'.</p>
            </Col>
          </Row>
        }
        <Modal isOpen={modalShow} toggle={this.toggle}>
          {modalType === 'create' &&
            <ProjectCreation availableForms={availableForms}
              confirmCreateProject={newProject => { this.props.confirmCreateProject(newProject); this.toggle(); }} />}
          {modalType === 'edit' &&
            <ProjectEdition availableForms={availableForms} project={selectedProject}
              confirmEditProject={updatedProject => { this.props.updateProject(selectedProject.id, updatedProject); this.toggle(); }} />}
        </Modal>
      </div>
    );
  }
}

ProjectsContainer.propTypes = {
  projects : PropTypes.object,
};

const mapStateToProps = state => ({ projects: state.projects.present });

const mapDispatchToProps = dispatch => ({
  getProjects: () => dispatch(projectsActions.getProjects()),
  confirmCreateProject: newProject => dispatch(projectsActions.createProject(newProject)),
  deleteProject: projectId => dispatch(projectsActions.deleteProject(projectId)),
  updateProject: (projectId, updatedProject) => dispatch(projectsActions.updateProject(projectId, updatedProject)),
});

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ProjectsContainer);
export { connectedComponent as ProjectsContainer };
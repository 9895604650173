import React from 'react';
import { Card, CardHeader, CardFooter, Row, Col } from 'reactstrap';
import { initCap } from 'lib/util';


class CardIndicator extends React.Component {
  render() {
    const { title, average, max, min } = this.props;
    return (
      <Col xs={12} sm={12} md={6} lg={4} xl={3} className="d-flex align-items-stretch" >
        <Card className="card-stats flex-fill">
          <CardHeader className="px-3">
            <Row>
              <Col>
                <h4 className="mt-0 mb-2">{initCap(title)}</h4>
              </Col>
            </Row>
          </CardHeader>
          <CardFooter className="px-3 pb-1">
            <Row>
              <Col className="pr-0 text-center my-auto" xs={6} sm={6} md={6} lg={6} xl={6} >
                <h6 className="my-0">Promedio</h6>
                <h4 className="my-0">{average.toFixed(2)}</h4>
              </Col>
              <Col className="px-0 text-center my-auto" xs={3} sm={3} md={3} lg={3} xl={3} >
                <h6 className="my-0">Max</h6>
                <h6 className="my-0">{max}</h6>
              </Col>
              <Col className="pl-0 text-center my-auto" xs={3} sm={3} md={3} lg={3} xl={3} >
                <h6 className="my-0">Min</h6>
                <h6 className="my-0">{min}</h6>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    );
  }
}

export { CardIndicator };

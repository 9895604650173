import { request, defaultHeaders, defaultJsonHeaders } from 'lib/request';
// import urlJoin from 'url-join';

const getUsers = async () => {
  const response = await request('/web/user/', {
    method: 'GET',
    ...defaultHeaders(),
  });
  return response.data;
};

const getRoles = async () => {
  const response = await request('/web/rol', {
    method: 'GET',
    ...defaultHeaders(),
  });
  return response.data;
};

const createUser = async newUser => {
  const response = await request('/web/user', {
    method: 'POST',
    ...defaultJsonHeaders(),
    body: JSON.stringify(newUser),
  });
  return response[0];
};

export { getUsers, getRoles, createUser };
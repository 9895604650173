import React from 'react';
import { Row, Card, CardBody, Form, FormGroup, Label, Col, Button, Input } from 'reactstrap';
import * as longinService from 'pages/login/loginService';
import { Link } from 'react-router-dom';
import { verifyValidEmail } from 'lib/util';


class RecoverPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      userEmail: '',
      isValidEmail: false,
      recoverPassResponse: { success : false, message : '' },
    };
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = async e => {
    const { userEmail, isValidEmail } = this.state;
    this.setState({ submitted: true, isValidEmail: verifyValidEmail(userEmail) });
    if (userEmail && isValidEmail) {
      const { data, message } = await longinService.recoverPassword(this.state.userEmail);
      if (data) {
        this.setState({ recoverPassResponse: { success: true, message }});
      }
    }
  }

  render() {
    const { userEmail, submitted, isValidEmail, recoverPassResponse } = this.state;
    return (
      <Row className="justify-content-center ffw-login-row align-items-center mx-0 vh-100">
        <Card className="ffw-card-login align-self-center">
          <CardBody>
            <h2>Recuperar contraseña</h2>
            <Form>
              <FormGroup className={(submitted && !userEmail ? 'has-error' : '')}>
                <Label htmlFor="userEmail">Correo de usuario</Label>
                <Input type="text" className="form-control" name="userEmail" value={this.state.userEmail} onChange={this.handleChange} />
                {submitted && !userEmail &&  <Label className="text-danger">El correo electronico es obligatorio</Label>}
                {submitted && !isValidEmail && <Label className="text-danger">El correo electronico no es valido</Label>}
              </FormGroup>
              <FormGroup>
                <Col className="text-right">
                  <Label>
                    <Link to="/login">¿Volver a Iniciar Sesión?</Link>
                  </Label>
                </Col>
              </FormGroup>
              {recoverPassResponse.success &&
                <FormGroup>
                  <Label className="text-success">{recoverPassResponse.message}</Label>
                </FormGroup>
              }
              <FormGroup>
                <Button className="btn btn-primary" onClick={this.handleSubmit}>Recuperar contraseña</Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Row>
    );
  }
}

export { RecoverPassword };
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import login from 'pages/login/loginRedux';
import projects from 'pages/projects/projectsRedux';
import campaigns from 'pages/campaigns/campaignsRedux';
import campaign from 'pages/campaign/campaignRedux';
import users from 'pages/users/usersRedux';
import { reducer as toastr } from 'react-redux-toastr';

const rootReducer = combineReducers({
  login,
  projects,
  campaigns,
  campaign,
  users,
  toastr,
});
const composeEnhancers = process.env.NODE_ENV !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware)),
);

export {
  store,
};
import React from 'react';
import { render } from 'react-dom';
import { App } from './App';
import { Provider } from 'react-redux';
import { store } from 'lib/store';
import ReduxToastr from 'react-redux-toastr';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-table/react-table.css';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/es';

import 'assets/scss/common.scss';


render(
  <Provider store={store}>
    <App />
    <ReduxToastr className="ffw-toastr" timeOut={5000} newestOnTop={false} transitionIn="fadeIn" transitionOut="fadeOut"
      closeOnToastrClick progressBar />
  </Provider>, document.getElementById('root'));

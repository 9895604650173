import { request, defaultHeaders, defaultJsonHeaders } from 'lib/request';
import urlJoin from 'url-join';


const getCampaigns = async projectId => {
  const response = await request(urlJoin('/web/project/', `${projectId}`, 'campaign'), {
    method: 'GET',
    ...defaultHeaders(),
  });
  return response.data;
};

const createCampaign = async (campaign) => {
  const response = await request('/web/campaign', {
    method: 'POST',
    ...defaultJsonHeaders(),
    body: JSON.stringify(campaign),
  });
  return response.data;
};

const deleteCampaign = async campaignId => {
  try {
    const response = await request(urlJoin('web/campaign/', `${campaignId}`), {
      method: 'DELETE',
      ...defaultHeaders(),
    });
    return response;
  } catch (e) {
    //request function manage this error
  }
};

const updateCampaign = async (campaignId, updatedCampaign)  => {
  try {
    const response = await request(urlJoin('web/campaign/', `${campaignId}`), {
      method: 'PATCH',
      ...defaultJsonHeaders(),
      body: JSON.stringify(updatedCampaign),
    });
    return response.data;
  } catch (e) {
    //request function manage this error
  }
};

const getAssignedUsers = async campaignId => {
  try {
    const response = await request(urlJoin('/web/campaign', `${campaignId}`, 'users'), {
      method: 'GET',
      ...defaultHeaders(),
    });
    return response.data;
  } catch (e) {
    //request function manage this error
  }
};

export { getCampaigns, createCampaign, deleteCampaign, updateCampaign, getAssignedUsers };
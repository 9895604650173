import React from 'react';
import { Row, Col } from 'reactstrap';
import { } from 'lib/util';
import { ActionCreators as UndoActionCreators } from 'redux-undo';
import { connect } from 'react-redux';


class ToastrMessageWithUndo extends React.Component {

  handleUndo = () => {
    this.props.applyInterception();
    this.props.undo();
  }

  render() {
    const { message } = this.props;
    return (
      <Row className="h-100">
        <Col xs={9} sm={9} md={9} lg={9} xl={9} className="my-auto" style={{ borderRight:'1px solid #1e4833' }}>
          {message}
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} xl={3} className="ffw-cursor-pointer  my-auto">
          <h6 className="mb-0" onClick={this.handleUndo}>Deshacer</h6>
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = ({
  undo: UndoActionCreators.undo,
  redo: UndoActionCreators.redo,
});

const connectedApp = connect(null, mapDispatchToProps)(ToastrMessageWithUndo);
export { connectedApp as ToastrMessageWithUndo };